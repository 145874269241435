import React from 'react'
import { images } from 'config/images'

export const HireDedicatedDevProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Hire <span className="heading-red">Dedicated Developer</span> for your
        project
      </>
    ),
    bannerContent: (
      <>
        Maximize Your ROI and Streamline Your Software Development with Our
        Dedicated Teams - Partner with Us and Start Saving Today!
      </>
    ),
    BannerAlt: 'Hire dedicated developers',
    buttonName: 'Get Free Consultation',
    buttonLink: '/contact/',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        <span className="highlight-head">World-class developers,</span>{' '}
        designers, and testers
      </>
    ),
    buildAppPara: (
      <>
        Say hello to your new team. Experts and specialists ready to{' '}
        <span className="color-through">extend your team</span> within 24 hours.
        Our value isn’t just limited to building teams but we own and guarantee
        the successful delivery of your project.
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote:
      'Building UX for a dating app that can reinvent the way people...',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  rangeServiceListDataProps: {
    RangeServiceListHeading: (
      <>
        Why hire dedicated developers from{' '}
        <span className="highlight-head">Simform</span>?
      </>
    ),
    detailsList: [
      <>
        <strong>Use of modernized technologies and practices: </strong> We use
        latest technologies and best engineering practices to craft advanced
        software products for your business. This includes the use of
        cloud-native technologies, DevOps practices, distributed microservices
        architetecture, and more.
      </>,
      <>
        <strong>Frequent reporting: </strong> Knowing the project status is a
        concern for any client, and we, at Simform, address it with daily or
        weekly reporting, as desired by the client.
      </>,
      <>
        <strong>Guaranteed on-time delivery: </strong> We keep our promise of
        delivering the project on time to give the client a competitive
        advantage in the market with a faster launch of products.
      </>,
      <>
        <strong>Experienced and knowledgeable team: </strong> You’re in safe
        hands when you partner with us. Your project gets handled by a highly
        skilled and seasoned developer team.
      </>,
      <>
        <strong>Diverse communication channels: </strong> Connecting with us for
        any query is easy. Apart from the project management system, you can
        connect with us over email, phone, Slack, and other important platforms.
        But most importantly, we always get back to you quickly.
      </>,
      <>
        <strong>Confidentiality and privacy: </strong> We give utmost importance
        to the confidentiality and privacy of data. We also do an NDA
        (Non-Disclosure Agreement) to give you confidence and security.
      </>,
      <>
        <strong>Reliable backup mechanism: </strong> Our developers use a 3-2-1
        backup strategy i.e. three copies of data (1 original + 2 back-ups), two
        different storages, and one off-site copy to ensure important client
        data doesn’t get lost in case of unwanted failures.
      </>,
      <>
        <strong>Single point of contact: </strong> If you hire our dedicated
        development team, we’ll assign a lead for that team. They ensure
        everyone across the team is on the same page and working towards your
        business goal.
      </>,
      <>
        <strong>Flexible hiring options: </strong> We ensure flexibility in
        scaling and pricing when you opt for our dedicated team of developers.
        You can choose the one that fits your business requirements.
      </>,
      <>
        <strong>Real-time tracking of the project: </strong> You can track your
        project's progress in real-time with the project management system.
        We’ll add you to that, and you can also add your viewpoint.
      </>,
      <>
        <strong>No expense on employee training: </strong> Hiring a dedicated
        team of developers will save a lot of time and money you’d have spent on
        training and retaining in-house employees.
      </>,
    ],
  },
  serviceBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of working with
        <br /> <span className="highlight-head"> Simform</span>
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Hand picked developers to give you the agility',
        workingBenefitsContent: (
          <>
            Extend your team with pre-vetted,{' '}
            <strong>world-class developers</strong> as part of your team. Our
            developers consist of senior engineers who have worked on projects
            from Fortune 1000 companies, successful startups, and popular
            open-source contributors.
          </>
        ),
        benefitImgAlt: 'World-class developers',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsContent: (
          <>
            We handle all aspects of finding, vetting, and{' '}
            <strong>choosing the right candidates</strong> that you don't have
            the time, focus, desire, or sometimes expertise to do. Whether you
            want to add a member to your existing engineering team or build a
            standalone project from scratch, we can help you find the best
            developers for the job.
          </>
        ),
        benefitImgAlt: 'Choose dedicated developers',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Motivated developers with flexible contract',
        workingBenefitsContent: (
          <>
            You get <strong>developers that take charge</strong> and are never
            blocked — always finding ways to add value and keep your project
            moving forward. This is essential when hiring remotely, as you will
            often not be there to keep them moving.
          </>
        ),
        benefitImgAlt: 'Developers contract',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Developing a shared understanding',
        workingBenefitsContent: (
          <>
            Over the years, we have learned that increased{' '}
            <strong>shared understanding</strong>
            is THE biggest factor for successful projects. Our developers spend
            time to understand your processes, users’ needs, and most
            importantly business goals.
          </>
        ),
        benefitImgAlt: 'Shared understanding',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'IP security, ownership and licensing',
        workingBenefitsContent: (
          <>
            <strong>Get complete ownership</strong> of the software built by
            Simform, so you’re free to use and commercialize it. Simform covers
            your risks through insurance coverage to protect and satisfy
            company-level obligations for our customers.
          </>
        ),
        benefitImgAlt: 'IP protection',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Cut costs with agile developer teams',
        workingBenefitsContent: (
          <>
            Work with vetted developers who follow quality engineering
            practices. Save costs by letting us take care of finding, vetting,
            recruiting, and training the developers. Plus, our flexible contract
            terms mean you hire dedicated programmers only when needed.
          </>
        ),
        benefitImgAlt: 'Cut costs with agile developer teams',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Accelerate deployments with modern approaches',
        workingBenefitsContent: (
          <>
            Speed up your development and deployment processes with our expert
            developers well-versed in modern methodologies and tools like CI/CD,
            DevOps, and more. Get your product to market faster and stay ahead
            of the curve.
          </>
        ),
        benefitImgAlt: 'Accelerate deployments with modern approaches',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Identify and address tech gaps',
        workingBenefitsContent: (
          <>
            Leverage our diverse, skilled app developers for hire to identify
            and bridge technology gaps in your project. Our teams are committed
            to elevating a project with the latest technologies, trends, and
            valuable insights.
          </>
        ),
        benefitImgAlt: 'Identify and address tech gaps',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Manage development risks with robust strategies',
        workingBenefitsContent: (
          <>
            Our developers work closely with you to gain a comprehensive
            understanding of your project to manage and mitigate risks
            associated with development challenges, using best practices to
            minimize the risk of delays, errors, or other issues.
          </>
        ),
        benefitImgAlt: 'Manage development risks with robust strategies',
      },
    ],
  },

  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        How we help you find
        <br /> the <span className="highlight-head">dedicated developer</span>
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'pale-lilac-three',
        workingBenefitsTitle: 'Contact us about your project',
        workingBenefitsContent: (
          <>
            Let us know about your <strong>project needs and goals</strong>—the
            more details the better. <br /> <br /> Our tech team then connects
            with you over a series of meetings in order to understand your
            business goals, project, tech stack, and all the other nuances that
            define your technical requirements.{' '}
          </>
        ),

        workingBenefitsPatern: images.processflow1,
        benefitImgAlt: 'Project goals',
      },
      {
        workingBenefitsClass: 'pale-mauve-three',
        workingBenefitsTitle: 'Tech roadmap with a hiring plan',
        workingBenefitsContent: (
          <>
            Depending on your goals, we prepare a <strong>tech roadmap</strong>{' '}
            with a <strong>hiring plan</strong> that includes details on what
            skill set and experience your team needs to have. <br /> <br /> The
            detailed tech roadmap includes things like what tech stack to use
            and how to navigate through tech complexities of the project.{' '}
          </>
        ),
        workingBenefitsPatern: images.processflow2,
        benefitImgAlt: 'Tech roadmap',
      },
      {
        workingBenefitsClass: 'light-sky-blue-two',
        workingBenefitsTitle: 'Sourcing talent to match your needs',
        workingBenefitsContent: (
          <>
            Firstly, we will dive deep into our talented, skilled, and
            experienced developers pool. Then, we’ll try to match your
            requirements against their skillsets and see if we can find a
            perfect match. However, if there is a need for additional talent, we
            will tap into our talent network and try to hire the best possible
            resources through our partners.
            <br /> <br /> We also run campaigns for talent hunting and use our
            recruitment capabilities to find the right person for your needs. We
            make sure your project requirement get the best talent-match
            possible.
          </>
        ),
        workingBenefitsPatern: images.processflow3,
        benefitImgAlt: 'Selecting dedicated developers',
      },
      {
        workingBenefitsClass: 'pale-four',
        workingBenefitsTitle: 'Our screening process',
        workingBenefitsContent: (
          <>
            When we have candidates we like we give them a stage to showcase
            their communication skills and{' '}
            <strong>problem-solving ability</strong> through a series of video
            interviews. <br /> <br /> Here, we are looking for people with
            highly relevant skill sets, <strong>well-documented code</strong>,
            and a disciplined approach to testing.{' '}
          </>
        ),
        workingBenefitsPatern: images.processflow4,
        benefitImgAlt: 'Screening process',
      },
      {
        workingBenefitsClass: 'light-periwinkle-two',
        workingBenefitsTitle: 'Test projects to measure skills',
        workingBenefitsContent: (
          <>
            Comprehensive small project tests are given to each developer. Their
            technical acumen is tested through an{' '}
            <strong>automated coding exam.</strong> <br /> <br /> Focus is given
            to <strong>language-specific knowledge</strong> as well as general
            programming and algorithm knowledge.
          </>
        ),
        workingBenefitsPatern: images.processflow5,
        benefitImgAlt: 'Testing projects',
      },
      {
        workingBenefitsClass: 'pale-sky-blue',
        workingBenefitsTitle: 'Selection and hiring',
        workingBenefitsContent: (
          <>
            We do full reference checks on your behalf with their previous
            clients and employers. We sign NDA and{' '}
            <strong>full proof legal contract</strong> to make sure your IP is
            protected. <br /> <br /> Speed is what Simform takes pride in — it
            typically takes only <strong>24-72 hours</strong> for our clients to
            receive a short list of top candidates.
          </>
        ),
        benefitImgAlt: 'Selection and hiring',
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Our recent <span className="highlight-head">success stories</span>
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },

  techstacksProps: {
    heading: 'Technology expertise',
    tabContents: [
      {
        tabName: 'Mobile',
        tabimages: [
          {
            src: images.iconReactNative,
            alt: 'ReactNative',
          },
          {
            src: images.iosSwift,
            alt: 'iosSwift',
          },
          {
            src: images.kotlinlangLogo,
            alt: 'kotlinlang',
          },
          {
            src: images.bitriseioLogo,
            alt: 'bitriseio',
          },
          {
            src: images.iconNative,
            alt: 'Native',
          },
        ],
      },
      {
        tabName: 'Web',
        tabimages: [
          {
            src: images.iconElectron,
            alt: 'Electron',
          },
          {
            src: images.socketioLogo,
            alt: 'socketio',
          },
          {
            src: images.knockoutLogo,
            alt: 'socketio',
          },
          {
            src: images.iconRubyOnRails,
            alt: 'Ruby On Rails',
          },
        ],
      },
      {
        tabName: 'Frontend development',
        tabimages: [
          {
            src: images.reduxSagaLogo,
            alt: 'reduxSaga',
          },
          {
            src: images.storyBookLogo,
            alt: 'storyBook',
          },
          {
            src: images.nextjsLogo,
            alt: 'nextjs',
          },
          {
            src: images.graphqlLogo,
            alt: 'graphql',
          },
          {
            src: images.RxSwiftLogo,
            alt: 'RxSwift',
          },
          {
            src: images.iconReact,
            alt: 'React',
          },
          {
            src: images.iconAngularJS,
            alt: 'AngularJS',
          },
          {
            src: images.iconVue,
            alt: 'Vue',
          },
        ],
      },
      {
        tabName: 'Backend',
        tabimages: [
          {
            src: images.iconPHP,
            alt: 'PHP',
          },
          {
            src: images.iconNodeJS,
            alt: 'Node.js',
          },
          {
            src: images.iconAspNET,
            alt: 'asp.Net (C#)',
          },

          {
            src: images.iconPython,
            alt: 'Python',
          },
        ],
      },
      {
        tabName: 'Headless CMS/e-commerce',
        tabimages: [
          {
            src: images.headlessLogo,
            alt: 'headless cms',
          },
          {
            src: images.iconShopify,
            alt: 'Shopify',
          },
          {
            src: images.iconStripe,
            alt: 'Stripe',
          },
          {
            src: images.iconMagento,
            alt: 'Magento',
          },
          {
            src: images.mauticLogo,
            alt: 'mautic',
          },
        ],
      },
      {
        tabName: 'Infrastructure',
        tabimages: [
          {
            src: images.elasticCacheLogo,
            alt: 'elasticCache',
          },
          {
            src: images.iconFirebase,
            alt: 'Firebase',
          },
          {
            src: images.terraformioLogo,
            alt: 'terraformio',
          },
          {
            src: images.iconJenkins,
            alt: 'Jenkins',
          },
          {
            src: images.iconRedis,
            alt: 'Redis',
          },
        ],
      },
      {
        tabName: 'Futuristic',
        tabimages: [
          {
            src: images.awsLogo,
            alt: 'aws',
          },
          {
            src: images.iconAzure,
            alt: 'Azure',
          },
          {
            src: images.iconGooglecloud,
            alt: 'Google cloud',
          },
          {
            src: images.devopsLogo,
            alt: 'devops',
          },
        ],
      },
      {
        tabName: 'Trending',
        tabimages: [
          {
            src: images.artificialIntelligenceLogo,
            alt: 'ai',
          },
          {
            src: images.blockchainLogo,
            alt: 'blockchain',
          },
          {
            src: images.iconSalesforce,
            alt: 'Salesforce',
          },
          {
            src: images.mlLogo,
            alt: 'ml',
          },
        ],
      },
      {
        tabName: 'Data Analytics',
        tabimages: [
          {
            src: images.mixpanelLogo,
            alt: 'mixpanel',
          },
          {
            src: images.powerbiLogo,
            alt: 'powerbi',
          },
          {
            src: images.tableauLogo,
            alt: 'tableau',
          },
        ],
      },
      {
        tabName: 'Database',
        tabimages: [
          {
            src: images.iconSQLServer,
            alt: 'SQLServer',
          },
          {
            src: images.iconPostageSQL,
            alt: 'PostageSQL',
          },
          {
            src: images.iconMySQL,
            alt: 'MySQL',
          },
        ],
      },
    ],
  },
  gauranteeSecProps: {
    heading: (
      <>
        Simform <span className="highlight-head">guarantee</span>
      </>
    ),
    para: (
      <>
        We know that if a client’s project launches smoothly, they’ll come back
        for more. We're{' '}
        <span className="color-through">willing to over-invest</span> in
        guaranteeing results, rather than under-invest to make our financial
        reports look pretty in the short-run.
        <br />
        <br />
        We offer a <strong>risk-free trial period</strong> of up to two weeks.
        You will only have to pay if you are happy with the developer and wish
        to continue. If you are unsatisfied,{' '}
        <span className="color-through">we’ll refund payment</span> or fix
        issues on our dime.
      </>
    ),
    buttonName: 'Get Free Quote',
    buttonURL: '/contact/',
    img: images.gauranteeTeam,
    img2x: images.gauranteeTeam2x,
    gauranteeAlt: 'Dedicated developers',
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/top-outsourcing-lessons-learned-from-5-successful-and-failed-startups/',
        featurecardTitle:
          '9 Outsourcing Lessons Learned from Successful and Failed startups',
      },
      {
        blogLink:
          'https://www.simform.com/building-scalable-application-aws-platform/',
        featurecardTitle:
          'How to Build a Scalable Application up to 1 Million Users on AWS',
      },
      {
        blogLink: 'https://www.simform.com/how-to-develop-ecommerce-apps/',
        featurecardTitle: 'How to Make an eCommerce App ',
      },
    ],
  },

  faqSectionProps: {
    heading: 'FAQs',
    faqProps: [
      {
        faqTitle:
          'How is Simform different from other online talent marketplaces, local agencies, and software consulting companies?',
        faqPara: (
          <>
            Freelance marketplaces like Upwork can help you connect with
            thousands of developers. They provide transparent access to devs,
            but you have to vet candidates, which can be tricky. And if it’s a
            slightly bigger project, having individual freelancers seamlessly
            collaborate with each other can be challenging.
            <br /> <br />
            Tech agencies and software consultancies follow outdated engagement
            and execution models. Transparency is also an issue since you are
            usually unaware of what’s happening behind the scenes. Instead, if
            you partner with us, we ensure guaranteed on-time delivery, desired
            results, transparency, and real-time collaboration.
            <br /> <br />
            In addition, Simform also cares about the confidentiality and
            privacy of the client, so we always sign an NDA (Non-Disclosure
            Agreement) with our clientele. We allow our clients to conduct our
            real-time tracking of their project’s progress. Besides that, we use
            agile development methodology, modern development approaches, and
            follow the latest industry standards to give our clients a
            competitive edge.
          </>
        ),
      },

      {
        faqTitle:
          'How do you make sure we get the best dedicated developers? What is your developer vetting process?',
        faqPara: (
          <>
            Screening talent with resumes and interviews is outdated. When you
            hire through us you don't only hire from limited visibility and
            information from resumes, personal interviews, etc.
            <br />
            <br />
            We have a comprehensive vetting process amongst our competitors.
            Developers are tested on their technical skills, communication
            skills, and project management skills.
            <br />
            <br />
            <strong>Our Vetting Process:</strong>
            <br />
            <br />
            1) We look internally to find the perfect candidates for you first.
            If we don't find the right candidate then we tap into our talent
            network to see if we can hire someone for you on a contract basis.
            We hunt, run campaigns, use our recruiting strength to hire
            candidates. When we have candidates we like we do the initial
            screening.
            <br />
            <br />
            2) Next, we dive deep into their portfolios and prior code samples.
            Each candidate is screened by experts in their functional domain.
            Here we are looking for people with creative problem solving, highly
            relevant skill sets, a disciplined approach to testing,
            well-documented code, and best practices.
            <br />
            <br />
            3) If a candidate passes through these then we test each developer’s
            technical acumen through an automated coding exam. Applicants are
            tested on language-specific knowledge as well as general programming
            and algorithm knowledge. Then a technical interview with a senior of
            our engineering team is set to evaluate their tech understanding.
            <br />
            <br />
            4) Then, we test each candidate’s ability to manage a remote
            engagement. We look for qualities every client cares about:
            communication habits, reliability, estimation skill, ability to
            prioritize, and more.
            <br />
            <br />
            It’s so crucial for anyone working as part of a distributed team to
            have great communication skills and that can be obvious from the
            first couple of interactions.
            <br />
            <br />
            We’ve worked with developers in more than 25 countries for more than
            a decade now. Over the years, there have been two things we consider
            to be critical in remote developers besides their tech capabilities.
            They have to be confident communicators and proactive.
            <br />
            <br />
            5) At last, we do full reference checks on your behalf with their
            previous clients and employers.
          </>
        ),
      },

      {
        faqTitle: 'How do you make working remotely successful?',
        faqPara: (
          <>
            Managing a remote team is not easy. There are critical mistakes one
            can make when trying to leverage the vast amounts of remote talent.
            <br /> <br />
            The solution: Communication, lots of it. Constant communication
            makes sure there can be absolutely no surprise breaks in execution.
            <br /> <br />
            Simform team integrates into your team, participating in standup and
            scrum meetings, weekly demos, weekly retrospectives.
            <br /> <br />
            <strong>Daily stand-ups</strong>
            <br /> <br />
            We do daily stand-ups where everyone gets on a video chat and tells
            you what they are working on that day and the previous day. <br />
            The Agile methodology calls for each contributor to go around, talk
            about what they’ve been working on, what they will be working on,
            estimate how long it’s going to take them, whether there are any
            critical blockers that could cause delay, and what their bandwidth
            looks like. These stand-ups are rigorously tracked by the Tech Lead.
            <br />
            <br />
            But we do not just rely on unstructured communication. Everyone at
            Simform uses PPP (progress, plans, problems) methodology which is
            used at companies like Facebook, Google, eBay, Skype, etc. PPP is
            used to detail progress, issues, thoughts, and anything else
            pertaining to their tasks.
            <br />
            <br />
            <strong>Weekly demonstrations</strong>
            <br /> <br />
            This one is simple: get everybody on a video chat, share screens,
            have people show their work, and then talk about it.
            <br /> <br />
            <strong>Weekly Retrospectives</strong>
            <br /> <br />
            A weekly retrospective is where you all collectively review what
            went well and what could have been improved based on the demo.
            <br />
            <br />
            We use the lean method of holding a meeting. It is great because it
            gives everyone a voice—there’s an element of democracy in the
            development process now. You’re still “the boss” but everybody now
            has some skin in the game. This will also help to organically create
            a culture within your remote team—something we will cover at length
            in a future post.
          </>
        ),
      },

      {
        faqTitle: 'Will the hired developer work only for me?',
        faqPara: (
          <>
            Yes, that’s what we offer- the developers you hire will work only on
            your project and terms. If you hire an entire dedicated developers’
            team then the entire team will work only on your project.
          </>
        ),
      },

      {
        faqTitle: "What if I am not satisfied with the developer's work?",
        faqPara: (
          <>
            In the unlikely case that you are not satisfied with one of our
            developers, we will replace him/her with someone having similar
            skills from our development team. And yes, you can thoroughly
            interview the developer before finalizing him/her.
          </>
        ),
      },
      {
        faqTitle: 'How much does it cost to hire dedicated developers?',
        faqPara: (
          <>
            The costs of hiring dedicated developers vary based on numerous
            factors such as the project requirements, the tech stack being used,
            the developers’ level of experience and expertise in specific tech,
            the length of the project, and so on. Simform offers flexible
            contract terms and pricing options so you can hire dedicated mobile
            developers without burning a hole in your pocket.
          </>
        ),
      },
      {
        faqTitle: 'Can I change the engagement model at a later stage?',
        faqPara: (
          <>
            At Simform, we offer flexible engagement models to meet the needs of
            our clients. We understand that priorities and requirements can
            change during the course of a project, and we are happy to
            accommodate any necessary adjustments. Our goal is to provide you
            with the best possible service and support throughout the entire
            development process.
          </>
        ),
      },
      {
        faqTitle: 'Why should you outsource to offshore developers?',
        faqPara: (
          <>
            Outsourcing offshore programmers can bring several benefits to
            businesses, including cost savings, access to a larger talent pool,
            and the ability to scale up or down as needed. Additionally, it
            allows businesses and their in-house teams to focus on their core
            competencies while leaving the technical aspects of development to
            experienced professionals.
          </>
        ),
      },
    ],
    contactHeading: 'Have more questions?',
    contactPara:
      'Let us know and our experts will get in touch with you ASAP. ',
    supportImg: images.contactLadySm,
    buttonName: 'Talk to our experts',
    buttonLink: '/contact/',
  },
}
