import React, { memo, useEffect } from 'react'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import { HireDedicatedDevProps } from '../../components/Props/hire-dedicated-developers'
import styled from 'styled-components'
import SEO from 'components/Seo'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import CaseStudySlider from 'components/CaseStudySlider/CaseStudySlider'
import { graphql, navigate } from 'gatsby'
import { images } from 'config/images'

import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import GuaranteeSection from 'components/GuaranteeSection/GuaranteeSection'
import FaqSection from 'components/FaqSection/FaqSection'
import FeatureResources from 'components/FeatureResources/FeatureResources'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { fetchItem, getViewerCountry } from '../../config/utils'
import RangeServiceListSec from 'components/RangeServiceListSec/RangeServiceListSec'
import Techstacks from 'components/Techstacks/Techstacks'
export const HireDedicatedDevPage = styled.div`
  .banner {
    .banner-content {
      max-width: 780px;
      p {
        max-width: 680px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
    .banner-img {
      display: block;
    }
  }
  .feature-resource-section {
    background: linear-gradient(to top, #eef2ff, #ffffff);
    .contact-info-card {
      display: none;
    }
  }
  .ppcbenefit-section {
    .heading-wrapper {
      max-width: 740px;
    }
  }
`

const DDT = memo(props => {
  const { data } = props
  const { location } = props
  // const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent: true })
  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
    props.data.process4.childImageSharp.fluid,
    props.data.process5.childImageSharp.fluid,
    props.data.process6.childImageSharp.fluid,
  ]
  let serviceBenefitsItem = [
    props.data.serviceBenefits1.childImageSharp.fluid,
    props.data.serviceBenefits2.childImageSharp.fluid,
    props.data.serviceBenefits3.childImageSharp.fluid,
    props.data.serviceBenefits4.childImageSharp.fluid,
    props.data.serviceBenefits5.childImageSharp.fluid,
    props.data.serviceBenefits6.childImageSharp.fluid,
    props.data.serviceBenefits7.childImageSharp.fluid,
    props.data.serviceBenefits8.childImageSharp.fluid,
    props.data.serviceBenefits9.childImageSharp.fluid,
  ]
  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  return (
    <Layout
      mainClass="hire-dedicated-dev services-page"
      location={location}
      data={{
        img: images.contactHiren,
        img2x: images.contactHiren_2x,
        name: 'Hiren Dhaduk',
        description:
          'Creating a tech product roadmap and building scalable apps for your organization.',
      }}
    >
      <SEO
        title="Hire Dedicated Developers & Programmers with Advanced skills set"
        description="Hire dedicated developers with Simform’s transparent team extension service You get to hire pre-vetted, world class developers who become part of your team."
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/hire/dedicated-developers/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/hire/dedicated-developers/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <HireDedicatedDevPage>
        <Banner
          bannerData={HireDedicatedDevProps}
          showBannerImage={true}
          {...props}
        />
        <div className="lazy-load-div">
          <BuildApplication
            buildApplicationData={HireDedicatedDevProps}
            posterImage={posterImage}
          />
          <RangeServiceListSec
            rangeServiceListData={
              HireDedicatedDevProps.rangeServiceListDataProps
            }
          />
          <ProcessFlow
            ProcessFlowData={HireDedicatedDevProps.serviceBenefitsProps}
            items={serviceBenefitsItem}
          />
        </div>
        <CaseStudySlider caseStudydata={HireDedicatedDevProps} {...props} />
        <ProcessFlow
          ProcessFlowData={HireDedicatedDevProps.workingBenefitsProps}
          items={processFlowItem}
        />
        <Techstacks techstacksdata={HireDedicatedDevProps} />
        <GuaranteeSection gauranteesecdata={HireDedicatedDevProps} {...props} />
        <FaqSection faqSectiondata={HireDedicatedDevProps} />
        <FeatureResources
          featureresourceData={HireDedicatedDevProps}
          {...props}
        />
      </HireDedicatedDevPage>
    </Layout>
  )
})

export default DDT

export const query = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/hire-dedicated-dev@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1198) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case1: file(relativePath: { regex: "/case-onlymob@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case2: file(relativePath: { regex: "/case-safari@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case3: file(relativePath: { regex: "/case-ipad@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case4: file(relativePath: { regex: "/case-mobile@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case5: file(relativePath: { regex: "/redbull-case@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    gauranteeTeam: file(relativePath: { regex: "/gaurantee-team@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1238) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(
      relativePath: { regex: "/blog-outsourcing-lessons@2x/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(
      relativePath: { regex: "/blog-build-scalable@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-ecommerce-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    servicebenefit: file(
      relativePath: { regex: "/hire-dedicated-benefit-banner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 720) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(relativePath: { regex: "/project-about-process@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 310) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(relativePath: { regex: "/hiring-plan-process@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 340) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(
      relativePath: { regex: "/sourcing-talent-process@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 328) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process4: file(relativePath: { regex: "/screening-process@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 340) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process5: file(relativePath: { regex: "/measure-skills-process@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 327) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process6: file(
      relativePath: { regex: "/selection-hiring-process@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 338) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits1: file(relativePath: { regex: "/ill-integrator@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits2: file(
      relativePath: { regex: "/ill-systemintegration@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits3: file(
      relativePath: { regex: "/ill-reviewassess@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits4: file(
      relativePath: { regex: "/ill-standardized@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits5: file(relativePath: { regex: "/ill-Apidesign@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits6: file(
      relativePath: { regex: "/cut-costs-agile-dev-teams@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits7: file(
      relativePath: { regex: "/accelerate-deployments-approaches@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits8: file(
      relativePath: { regex: "/identify-address-tech-gaps@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits9: file(
      relativePath: { regex: "/dev-risks-robust-strategies@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
